<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-img
        @contextmenu.prevent
        src="@/assets/ilustration_about_01.svg"
        lazy-src="@/assets/ilustration_about_01.svg"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        max-height="470"
        cover
        class="align-center"
      >
        <v-card-title class="text-white text-h4 text-center font-weight-bold">
          <p>{{ $t("about-us.quotes[0]") }}</p>
          <p>{{ $t("about-us.quotes[1]") }}</p>
          <p>{{ $t("about-us.quotes[2]") }}</p>
        </v-card-title>
      </v-img>
    </v-card>

    <v-row align="center" justify="center" class="bg-primary-container-dark">
      <v-col cols="10">
        <v-sheet width="auto" class="pt-4 px-16" color="primary-container-dark">
          <p class="text-center text-h5 font-weight-bold pb-3">
            {{ $t("about-us.info[0].title") }}
          </p>
          <p class="text-justify text-subtitle-1 pb-7">
            {{ $t("about-us.info[0].description") }}
          </p>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row class="my-6" no-gutters align="center" justify="center">
      <v-col cols="12">
        <p class="text-center text-h6 font-weight-bold">
          {{ $t("about-us.info[1].title") }}
        </p>
      </v-col>

      <v-col
        class="text-center ma-2"
        v-for="({ title, subtitle, src }, i) in sure"
        :key="i"
        cols="12"
        sm="3"
        align-self="start"
      >
        <v-img
          @contextmenu.prevent
          class="d-inline-block"
          :src="src"
          :lazy-src="src"
          width="200"
          height="200"
        ></v-img>
        <p class="text-body-1 font-weight-bold">{{ title }}</p>
        <p class="text-subtitle-1">{{ subtitle }}</p>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      class="bg-tertiary-container-dark py-8"
      justify="space-around"
      align="center"
    >
      <v-col cols="5">
        <p class="text-h5 font-weight-bold">
          {{ $t("about-us.info[2].title") }}
        </p>
        <p>
          {{ $t("about-us.info[2].description") }}
        </p>
      </v-col>

      <v-col cols="5" class="text-center">
        <v-btn
          @click="goToURL(url.web_seller + 'signup')"
          color="white"
          class="text-background-dark text-none"
        >
          {{ $t("about-us.button.register-ticket") }}</v-btn
        >
      </v-col>
    </v-row>

    <v-row align="center" justify="space-around">
      <v-col cols="6">
        <v-sheet width="auto" class="d-flex justify-center flex-row">
          <v-img
            @contextmenu.prevent
            width="250"
            src="@/assets/ilustration_about_02.svg"
            lazy-src="@/assets/ilustration_about_02.svg"
          ></v-img>
        </v-sheet>
      </v-col>

      <v-col cols="6">
        <p class="text-h6 font-weight-bold text-center">
          {{ $t("about-us.info[3].title") }}
        </p>
        <p class="text-subtitle-1 text-center">
          {{ $t("about-us.info[3].description") }}
        </p>
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <v-img
              @contextmenu.prevent
              width="210"
              src="@/assets/google-play-badge.png"
              lazy-src="@/assets/google-play-badge.png"
            ></v-img>
          </v-col>
          <v-col cols="auto">
            <v-img
              @contextmenu.prevent
              width="165"
              src="@/assets/app-store-badge.svg"
              lazy-src="@/assets/app-store-badge.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotSure1 from "@/assets/01_ilustration_segment_not_sure.svg";
import NotSure2 from "@/assets/02_ilustration_segment_not_sure.svg";
import NotSure3 from "@/assets/03_ilustration_segment_not_sure.svg";

export default {
  data() {
    return {
      url: {
        web_seller: process.env.VUE_APP_WEB_SELLER_URL,
      },
      sure: [
        {
          title: this.$t("about-us.info[1].contents[0].title"),
          subtitle: this.$t("about-us.info[1].contents[0].subtitle"),
          src: NotSure1,
        },
        {
          title: this.$t("about-us.info[1].contents[1].title"),
          subtitle: this.$t("about-us.info[1].contents[1].subtitle"),
          src: NotSure2,
        },
        {
          title: this.$t("about-us.info[1].contents[2].title"),
          subtitle: this.$t("about-us.info[1].contents[2].subtitle"),
          src: NotSure3,
        },
      ],
    };
  },
  methods: {
    goToURL(url) {
      window.open(url, "_blank");
    },
  },
};
</script>